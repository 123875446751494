<template>
  <div class="container-fluid">
    <div class="row mb-5" v-if="showHeader">
      <div class="col-8">
        <span class="display-4 col-12">{{ $t("COMMON.DETAILS") }}</span>
      </div>
      <div class="col-4 text-right">
        <base-button
          @click="openPurchasesOrderItemModal()"
          type="button"
          class="btn btn-sm btn-primary"
          v-if="purchasesOrder.status === ORDER_STATUS_DRAFT"
        >
          <span class="btn-inner--icon"><i class="fas fa-plus"></i></span>
          {{ $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER_ITEM") }}
        </base-button>
      </div>
    </div>

    <purchases-order-view-details-purchases-order-item-table
      :purchasesOrder="purchasesOrder"
      @onOpenPurchasesOrderItemModal="openPurchasesOrderItemModal"
    />

    <div class="row mb-5" v-if="purchasesOrder.pricing">
      <div class="col-6"></div>
      <div class="col-6">
        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.SUBTOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesOrder.pricing.subtotal) }}
          </dd>
        </dl>

        <dl class="row mb-0" v-if="purchasesOrder.pricing.discounts.total">
          <dt class="col-sm-6">{{ $t("COMMON.DISCOUNTS") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesOrder.pricing.discounts.total * -1) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(discount, index) in purchasesOrder.pricing.discounts
                .details"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ discount.name }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(discount.amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0" v-if="purchasesOrder.pricing.taxes.total">
          <dt class="col-sm-6">{{ $t("COMMON.TAXES") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesOrder.pricing.taxes.total) }}
          </dd>
          <div class="col-12">
            <dl
              class="row mb-0"
              v-for="(tax, index) in purchasesOrder.pricing.taxes.taxes"
              v-bind:key="index"
            >
              <dt class="col-sm-6 pl-5 text-muted">{{ tax.label }}</dt>
              <dd class="col-sm-6 text-right text-muted">
                {{ $formatCurrency(tax.tax_amount) }}
              </dd>
            </dl>
          </div>
        </dl>

        <dl class="row mb-0">
          <dt class="col-sm-6">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-6 text-right">
            {{ $formatCurrency(purchasesOrder.pricing.total) }}
          </dd>
        </dl>
      </div>
    </div>

    <!-- Modal add slot -->
    <purchases-order-view-details-purchases-order-item-form
      :purchasesOrder="purchasesOrder"
      :purchasesOrderItem="purchasesOrderItem"
      :showModal="showModal"
      :formErrors="itemsFormError"
      @onClosePurchasesOrderItemModal="closePurchasesOrderItemModal"
      @onSubmitPurchasesOrderItemForm="handlePurchasesOrderItemFormSubmit"
      @onDeletePurchasesOrderItem="deletePurchasesOrderItem"
    />
  </div>
</template>

<script>
import { Button, Tooltip } from "element-ui";
import { cloneDeep } from "lodash";
import { ORDER_STATUS_CANCELED, ORDER_STATUS_DRAFT } from "@/constants/orders";
import PurchasesOrderViewDetailsPurchasesOrderItemForm from "./PurchasesOrderViewDetailsPurchasesOrderItemForm.vue";
import PurchasesOrderViewDetailsPurchasesOrderItemTable from "./PurchasesOrderViewDetailsPurchasesOrderItemTable.vue";
import defaultPurchasesOrderItem from "../defaultPurchasesOrderItem";

export default {
  name: "purchases-order-view-details",

  components: {
    PurchasesOrderViewDetailsPurchasesOrderItemForm,
    PurchasesOrderViewDetailsPurchasesOrderItemTable,
    [Tooltip.name]: Tooltip,
    [Button.name]: Button,
  },

  mixins: [],

  props: {
    purchasesOrder: {
      default: {},
    },

    showHeader: {
      default: true,
      type: Boolean,
    },
  },

  data() {
    return {
      purchasesOrderItem: cloneDeep(defaultPurchasesOrderItem),
      loading: false,
      showModal: false,
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
      ORDER_STATUS_CANCELED: ORDER_STATUS_CANCELED,
      itemsFormError: null,
    };
  },

  computed: {},

  methods: {
    openPurchasesOrderItemModal(purchasesOrderItem = null) {
      if (!purchasesOrderItem) {
        this.purchasesOrderItem = cloneDeep(defaultPurchasesOrderItem);
      } else {
        this.purchasesOrderItem = cloneDeep(purchasesOrderItem);
      }
      this.showModal = true;
    },

    closePurchasesOrderItemModal() {
      this.showModal = false;
      this.loading = false;
    },

    handlePurchasesOrderItemFormSubmit(purchasesOrderItemData) {
      if (!purchasesOrderItemData.id) {
        this.addPurchasesOrderItem(purchasesOrderItemData);
      } else {
        this.editPurchasesOrderItem(purchasesOrderItemData);
      }
    },

    async addPurchasesOrderItem(purchasesOrderItemData) {
      this.loading = true;
      try {
        purchasesOrderItemData.purchasesOrder.id = this.purchasesOrder.id;

        await this.$store.dispatch(
          "purchasesOrderItems/add",
          purchasesOrderItemData
        );
        this.$emit("purchasesOrderItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_ITEM_ADDED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async editPurchasesOrderItem(purchasesOrderItemData) {
      this.loading = true;
      try {
        await this.$store.dispatch(
          "purchasesOrderItems/update",
          purchasesOrderItemData
        );
        this.$emit("purchasesOrderItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_ITEM_EDITED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.itemsFormError = error.response.data.errors;
        this.loading = false;
      }
    },

    async deletePurchasesOrderItem(id) {
      this.loading = true;
      try {
        await this.$store.dispatch("purchasesOrderItems/destroy", id);
        this.$emit("purchasesOrderItemsUpdated", null);

        this.$notify({
          type: "success",
          message: this.$t("PURCHASES_ORDERS.PURCHASES_ORDER_ITEM_DELETED"),
        });

        this.loading = false;
        this.showModal = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },
  },

  mounted() {},

  watch: {},
};
</script>
