<template>
  <div class="row mb-5">
    <el-table
      class="table-responsive align-items-center table-flush"
      header-row-class-name="thead-light"
      :data="purchasesOrder.items"
    >
      <el-table-column :label="$t('COMMON.CODE')" prop="code">
        <template v-slot="{ row }">
          <div v-if="row.purchasesOrderable">
            <a
              href="#"
              @click.prevent="goToPurchasesOrderable(row.purchasesOrderable)"
            >
              {{ row.code }}<br />
              <div
                class="text-muted purchasesOrder-item-excerpt"
                v-html="row.excerpt"
              ></div>
            </a>
          </div>
          <div v-if="!row.purchasesOrderable">
            {{ row.code }}<br />
            <div
              class="text-muted purchasesOrder-item-excerpt"
              v-html="row.excerpt"
            ></div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.UNIT_PRICE')" prop="unit_price">
        <template v-slot="{ row }"> ${{ row.unit_price }} </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.QUANTITY')" prop="quantity">
        <template v-slot="{ row }">
          {{ row.quantity }}
          <object-link :object="row.unitOfMeasureUnit" />
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.SUBTOTAL')" prop="discount">
        <template v-slot="{ row }">
          ${{ row.pricing.subtotal.toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.DISCOUNT')" prop="discount">
        <template v-slot="{ row }">
          ${{ row.pricing.discount_amount.toFixed(2) }}
          <span class="text-muted">({{ row.discount }}%)</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.TAXES')">
        <template v-slot="{ row }">
          ${{ row.pricing.taxes.total.toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('COMMON.TOTAL')">
        <template v-slot="{ row }">
          ${{ row.pricing.total.toFixed(2) }}
        </template>
      </el-table-column>

      <el-table-column min-width="50px" align="center">
        <div slot-scope="{ row }" class="table-actions">
          <el-tooltip
            :content="$t('COMMON.EDIT')"
            placement="top"
            v-if="purchasesOrder.status === ORDER_STATUS_DRAFT"
          >
            <a
              type="text"
              @click="openPurchasesOrderItemModal(row)"
              class="table-action"
              data-toggle="tooltip"
              style="cursor: pointer"
            >
              <i class="fas fa-edit"></i>
            </a>
          </el-tooltip>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import { ORDER_STATUS_DRAFT } from "@/constants/orders";

export default {
  name: "purchasesOrder-view-details-purchasesOrder-item-table",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin],

  props: ["purchasesOrder"],

  data() {
    return {
      ORDER_STATUS_DRAFT: ORDER_STATUS_DRAFT,
    };
  },

  computed: {},

  methods: {
    openPurchasesOrderItemModal(purchasesOrderItem) {
      this.$emit("onOpenPurchasesOrderItemModal", purchasesOrderItem);
    },

    async goToPurchasesOrderable(purchasesOrderable) {
      this.$router.push(this.$objectViewRoute(purchasesOrderable));
    },
  },

  mounted() {},

  watch: {},
};
</script>

<style>
.purchasesOrder-item-excerpt p,
.purchasesOrder-item-excerpt,
.purchasesOrder-item-excerpt * {
  font-size: 0.7rem;
}
</style>
