<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <h1>{{ purchasesOrder.code }}</h1>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(purchasesOrder.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PURCHASES_ORDERS.EXPIRATION_TIME") }}</dt>
          <dd class="col-sm-8">
            {{ $formatDate(purchasesOrder.expiration_time) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
          <dd class="col-sm-8">
            <locations :locations="purchasesOrder.allowedLocations" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="purchasesOrder.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.STATUS") }}</dt>
          <dd class="col-sm-8">
            <purchases-order-status-badge
              :purchasesOrder="purchasesOrder"
              :advanced="true"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("PURCHASES_ORDERS.EXCLUDING_TAXES") }}</dt>
          <dd class="col-sm-8">
            <span v-if="purchasesOrder.has_no_taxes">
              {{ $t("COMMON.YES") }}
            </span>
            <span v-else>
              {{ $t("COMMON.NO") }}
            </span>
          </dd>
        </dl>

        <dl class="row" v-if="purchasesOrder.purchasesInvoice">
          <dt class="col-sm-4">{{ $t("COMMON.PURCHASES_INVOICE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesOrder.purchasesInvoice" />
            <purchases-invoice-status-badge
              :purchasesInvoice="purchasesOrder.purchasesInvoice"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.ISSUER") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesOrder.issuer" />
          </dd>
        </dl>

        <dl class="row" v-if="purchasesOrder.destinationWarehouse">
          <dt class="col-sm-4">{{ $t("COMMON.WAREHOUSE") }}</dt>
          <dd class="col-sm-8">
            <object-link :object="purchasesOrder.destinationWarehouse" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TOTAL") }}</dt>
          <dd class="col-sm-8" v-if="purchasesOrder.pricing">
            <span> {{ $formatCurrency(purchasesOrder.pricing.total) }} </span>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4" v-if="purchasesOrder.excerpt">
            {{ $t("COMMON.NOTE") }}
          </dt>
          <dd class="col-sm-8" v-if="purchasesOrder.excerpt">
            <div v-html="purchasesOrder.excerpt"></div>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import PurchasesInvoiceStatusBadge from "../../PurchasesInvoiceManagement/partials/PurchasesInvoiceStatusBadge.vue";
import PurchasesOrderStatusBadge from "./PurchasesOrderStatusBadge.vue";

export default {
  name: "purchasesOrder-view-global",

  components: {
    PurchasesOrderStatusBadge,
    PurchasesInvoiceStatusBadge,
  },

  props: ["purchasesOrder"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    purchasesOrder(purchasesOrder) {},
  },
};
</script>
